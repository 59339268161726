<script>
    import {Router, Link, Route} from "svelte-routing";
    import Home from "./EditorRoute.svelte";
    import PrivacyRoute from "./PrivacyRoute.svelte";
    import ContactRoute from "./ContactRoute.svelte";

    export let url = "";
</script>

<Router {url}>
    <div>
        <Route path="/privacy" component={PrivacyRoute}/>
        <Route path="/contact" component={ContactRoute}/>
        <Route path="/">
            <Home/>
        </Route>
    </div>
    <nav class="bg-blue-500 text-white p-4">
        <ul class="flex space-x-4 justify-center">
            <li>
                <Link class="hover:bg-blue-700 px-3 py-2 rounded" to="/">Edit PDF</Link>
            </li>
            <li>
                <Link class="hover:bg-blue-700 px-3 py-2 rounded" to="/privacy">Privacy Policy</Link>
            </li>
            <li>
                <Link class="hover:bg-blue-700 px-3 py-2 rounded" to="/contact">Contact</Link>
            </li>
        </ul>
    </nav>
</Router>